<template>
    <div class="page-container">
        <div class="page-title">
            <img src="../../assets/icon-artist-arrow.png" alt="" class="arrow-left">
            <div>艺术家</div>
            <img src="../../assets/icon-artist-arrow.png" alt="" class="arrow-right">
        </div>
        <div class="artis-list">
            <div class="artis-item">
                <div class="item-top">
                    <div class="logo-title">
                        <img src="../../assets/tmp-head1.png" alt="" class="item-logo">
                        <div class="item-title">北京信息产业协会元宇宙专家委员会（筹）</div>
                    </div>
                    <div class="title-aside">北京信息产业协会元宇宙专家委员会（筹）致力于保证中国元宇宙行业的健康发展、引领中国…</div>
                </div>
                <div class="item-bottom">
                    <img src="../../assets/tmp-article1.png" alt="" class="item-cover">
                    <div class="bottom-txt">《中国元宇宙白皮书（2022）》收藏证书</div>
                </div>
            </div>
            <div class="artis-item">
                <div class="item-top">
                    <div class="logo-title">
                        <img src="../../assets/tmp-head.png" alt="" class="item-logo">
                        <div class="item-title">黄金九六之家</div>
                    </div>
                    <div class="title-aside">国篮96黄金一代主帅宫鲁鸣发起创办基于情怀， 始于专业。展现中国男篮96黄金一代的精神…</div>
                </div>
                <div class="item-bottom">
                    <img src="../../assets/tmp-article.png" alt="" class="item-cover">
                    <div class="bottom-txt">国篮96黄金一代</div>
                </div>
            </div>
            <div class="artis-item">
                <div class="item-top">
                    <div class="logo-title">
                        <img src="../../assets/tmp-head3.png" alt="" class="item-logo">
                        <div class="item-title">墨霏链</div>
                    </div>
                    <div class="title-aside">墨霏代表作《屌丝男士》，《男模的秘密》资深传媒人十余年文娱产业经验，原CCTV6,光线…</div>
                </div>
                <div class="item-bottom">
                    <img src="../../assets/tmp-article2.png" alt="" class="item-cover">
                    <div class="bottom-txt">墨霏与艺术红酒</div>
                </div>
            </div>
            <div class="artis-item">
                <div class="item-top">
                    <div class="logo-title">
                        <img src="../../assets/tmp-head2.png" alt="" class="item-logo">
                        <div class="item-title">王喜文</div>
                    </div>
                    <div class="title-aside">王喜文，数字艺术家，数字经济专家，工学博士，情报学博士后。自幼学习国画，所画风格多样…</div>
                </div>
                <div class="item-bottom">
                    <img src="../../assets/tmp-article3.png" alt="" class="item-cover">
                    <div class="bottom-txt">国风佛系数字绘画</div>
                </div>
            </div>
            <div class="artis-item hidden"></div>
            <div class="artis-item hidden"></div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" src="./index.scss" scoped>

</style>